import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const StudyAbroad = () => {
    const navigate = useNavigate();

    const handleCountryClick = (country) => {
        navigate(`/about-country-${country}`);
    };

    const countriesData = [
        { 
            name: 'USA',
            applicationStatus: 'closing soon',
            programs: 'Bachelor / Masters / summer internship',
            scholarships: 'closing soon',
            admissionInterview: 'No',
            tuitionFeeDeposit: 'Deposit',
            visaInterview: 'Yes',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Depends on the spot',
            visaTAT: 'N/A',
            imgUrl: './images/flag/'
        },
        { 
            name: 'UK',
            applicationStatus: 'closing soon',
            programs: 'Bachelor / Masters / short term courses',
            scholarships: 'depends',
            admissionInterview: 'Yes',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'Depends',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '15-30 working days',
            imgUrl: './images/flag/United-Kingdom.webp'
        },
        { 
            name: 'France',
            applicationStatus: 'Closing soon',
            programs: 'Bachelor / Masters',
            scholarships: 'depends',
            admissionInterview: 'Yes',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'Yes',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '15-45 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'Italy',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Fully Funded',
            admissionInterview: 'depends',
            tuitionFeeDeposit: 'N/A',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '15-45 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'Spain',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Depends',
            admissionInterview: 'Yes',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '15-45 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'Australia',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Depends',
            admissionInterview: 'Yes',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '15-45 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'New Zealand',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Depends',
            admissionInterview: 'Yes',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '15-45 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'Romania',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Scholarship',
            admissionInterview: 'Yes',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '15-45 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'Dubai',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Depends',
            admissionInterview: 'No',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '07-10 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'Ireland',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Depends',
            admissionInterview: 'Depends',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Easy',
            visaTAT: '45-90 working days',
            imgUrl: './images/flag/'
        },
        { 
            name: 'Germany',
            applicationStatus: 'Open',
            programs: 'Bachelor / Masters',
            scholarships: 'Depends',
            admissionInterview: 'Depends',
            tuitionFeeDeposit: '50% tuition fee',
            visaInterview: 'No',
            accommodation: 'Guidance Required',
            visaSlotAvailability: 'Medium',
            visaTAT: '15-45 working days',
            imgUrl: './images/flag/'
        }
    ];
    

    return (
        <>
            <SEO title="Study Abroad" />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <img className="radius-small" src="/images/others/studyabroad.png" style={{ height: '500px' }} alt="Study Abroad" />
                                    <h2 className="title">Study Abroad with FETC</h2>
                                </div>

                                <p>Unlock a world of opportunities with FETC, your trusted partner in pursuing education overseas. At FETC, we understand that studying abroad is more than just enrolling in a university—it's about embarking on a life-changing journey that shapes your future. We are dedicated to providing comprehensive and personalized support at every stage of your study abroad experience. From selecting the right course and university to guiding you through the intricacies of visa applications, FETC is here to make your dreams of international education a reality.</p>

                                <h4 className="title">Explore Prime Study Destinations</h4>

                                <p>Choosing the right destination is crucial to your academic and career success. Each country offers unique advantages, and at FETC, we have the expertise to help you find the perfect fit. Explore our top study destinations below, and click on your preferred country to discover why it's the ideal place to further your education.</p>

                                <div className="row g-5 mt--20">
                                    {countriesData.map((country, index) => (
                                        <div className="col-lg-6 col-md-12" key={index}>
                                            <div className="contact-address-card-2" style={{ cursor: 'pointer' }} onClick={() => handleCountryClick(country.name.toLowerCase())}>
                                                <div className="inner">
                                                    <div className="icon" style={{ textAlign: 'center', marginBottom: '10px' }}>
                                                        <img src={country.imgUrl} alt={country.name} style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
                                                    </div>
                                                    <div className="content" style={{ textAlign: 'center' }}>
                                                        <h6 className="title" style={{ fontSize: '24px' }}>
                                                            {country.name}
                                                        </h6>
                                                        <p>{country.applicationStatus} - {country.programs}</p>
                                                    </div>
                                                    <div className="country-details" style={{ marginTop: '20px', padding: '10px', backgroundColor: '#f8f8f8', borderRadius: '8px' }}>
                                                        <h6>Application Status: {country.applicationStatus}</h6>
                                                        <h6>Programs: {country.programs}</h6>
                                                        <h6>Scholarships: {country.scholarships}</h6>
                                                        <h6>Admission Interview: {country.admissionInterview}</h6>
                                                        <h6>Tuition Fee/Deposit: {country.tuitionFeeDeposit}</h6>
                                                        <h6>Visa Interview: {country.visaInterview}</h6>
                                                        <h6>Accommodation: {country.accommodation}</h6>
                                                        <h6>Visa Slot Availability: {country.visaSlotAvailability}</h6>
                                                        <h6>Visa TAT: {country.visaTAT}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default StudyAbroad;
