import React from 'react';

const FreeConsultation = () => {
    return (
        <>
            <div id="contact" className="eduvibe-contact-me-top edu-contact-me-area edu-section-gap bg-color-white">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-8">
                            {/* Brevo Form Embed */}
                            <div style={{ width: '100%', height: '600px', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
                                <iframe 
                                    src="https://dd46d8bd.sibforms.com/serve/MUIFAJOitRJUG0kXbsQOkYErd3cQOjB-t9JoQ0jMHTH0kUm3ALqrVbChBowquYGAYtPYGuCzarYxKInm3Any1XlUV3QjutX-XwqWUk3UqYX612iEsQTG9l3rAGyyP9RPuAjzeuWSBIpGt3c3ONmuKswFUn4jj_lI3EVQ7nlOlfBLmzV1vszlxI35DkOkEcoZnwz_SBUWUOrjVnI5"
                                    width="100%"
                                    height="600px"
                                    frameBorder="0"
                                    scrolling="no"
                                    style={{ border: 'none' }}
                                    title="Brevo Contact Form"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FreeConsultation;
