import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="">
                <Link to="/">Home</Link>
            </li>
            <li className="">
                <Link to="/work-permit">Work Permit</Link>
            </li>
            <li className="">
                <Link to="/career-assessment">Career Assessment</Link>
            </li>
            <li className="has-droupdown">
                <Link to="#">Book an Exam</Link>
                <ul className="submenu">
                <li><Link to="/selt">UKVI - SELT</Link></li>
                    <li><Link to="/toefl">ETS - TOEFL</Link></li>
                    <li><Link to="/ielts">IDP - IELTS</Link></li>
                    <li><Link to="/pte-versant-exam">PEARSON VERSANT</Link></li>
                    <li><Link to="/gre#">GRE</Link></li>
                    <li><Link to="/gmat">GMAT</Link></li>
                    <li><Link to="/sat">SAT</Link></li>
                  </ul>
                </li>

                <li className="has-droupdown">
                <Link to="#">Training/Mock</Link>
                <ul className="submenu">
                <li className="has-droupdown">
                        <Link to="#">Mock</Link>
                        <ul className="submenu">
                            <li><Link to="/toefl">ETS-TOEFL</Link></li>
                            <li><Link to="/ielts">SFE-SELT</Link></li>
                            <li><Link to="/pte-versant-exam">PEARSON VERSANT</Link></li>
                        </ul>
                    </li>

                <li className="has-droupdown">
                        <Link to="#">Offline</Link>
                        <ul className="submenu">
                        <li><Link to="https://maps.app.goo.gl/bMtkZiBTZjR8ydAV6">VIP (Surat)</Link></li>
                            <li><Link to="https://maps.app.goo.gl/QTm4EhqhDi1c9X6e7">Varaccha (Surat)</Link></li>
                            <li><Link to="https://maps.app.goo.gl/MtpYZnAS5ntYnYDi8">Rajkot</Link></li>
                            </ul>
                            </li>
                </ul>
                </li>
                    
             <li className="has-droupdown">
                <Link to="#">SyZyGy</Link>
                <ul className="submenu">
                    <li><Link to="/unit1c-course/1#">Demo</Link></li>
                    <li><Link to="#">Register</Link></li>
                </ul>
            </li>
            
            
            <li className="has-droupdown">
                <Link to="#">Study Abroad</Link>
                <ul className="submenu">
                <li><Link to="/about-country-usa">USA</Link></li>
                <li><Link to="/about-country-uk">UK</Link></li>
                <li><Link to="/about-country-australia">AUS</Link></li>
                <li><Link to="/about-country-new-zealand">NZ</Link></li>
                <li><Link to="/about-country-europe">Europe</Link></li>
                <li><Link to="/about-country-canada">Canada</Link></li>
                <li><Link to="/about-country-dubai">Dubai</Link></li>  
                <li><Link to="/about-country-ireland">Ireland</Link></li>   
                </ul>
            </li>
            <li><Link to="/blog">Blog</Link></li>
            {/*<li><Link to="/contact-us">Contact Us</Link></li>*/}
           
            
            {/* <li className="has-droupdown"><Link to="#">Blog</Link>
                <ul className="submenu">
                    <li><Link to="/blog-grid-1">Blog Grid 1</Link></li>
                    <li><Link to="/blog-grid-2">Blog Grid 2</Link></li>
                    <li><Link to="/blog-grid-3">Blog Grid 3</Link></li>
                    <li><Link to="/blog-carousel">Blog Carousel</Link></li>
                    <li><Link to="/blog-standard">Blog Standard</Link></li>
                    <li><Link to="/blog-grid-right-sidebar">Blog( Right Sidebar )</Link></li>
                    <li><Link to="/blog-grid-left-sidebar">Blog( Left Sidebar )</Link></li>
                    <li><Link to="/blog-load-more">Blog( Load More )</Link></li>
                    <li><Link to="/blog-details/1">Blog Details 1</Link></li>
                    <li><Link to="/blog-details-left-sidebar/1">Blog Details 2</Link></li>
                    <li><Link to="/blog-details-right-sidebar/1">Blog Details 3</Link></li>
                    <li><Link to="/category/education">Category Archive</Link></li>
                    <li><Link to="/tag/finance">Tag Archive</Link></li>
                    <li><Link to="/author/hazel-thomas">Author Archive</Link></li>
                </ul>
            </li> */}

            {/* <li className="">
                <Link to="/contact-me">Contact Us</Link>
            </li> */}
            {/* <li className="has-droupdown">
                <Link to="/">Contact</Link>
                <ul className="submenu">
                    <li><Link to="/contact-me">Contact Me</Link></li>
                    <li><Link to="/contact-us">Contact Us</Link></li>
                </ul>
            </li> */}
        </ul>
    )
}
export default Nav;
