import React from 'react';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { Navigate } from 'react-router-dom';

export const WorkPermit = () => {

    // Define the countries with flag images and country names
    const countries = [
        { name: 'Argentina', image: './images/flag/Argentina.webp' },
        { name: 'Austria', image: './images/flag/Austria.webp' },
        { name: 'Bosnia', image: './images/flag/Bosnia.webp' },
        { name: 'Bulgaria', image: './images/flag/Bulgaria.webp' },
        { name: 'Canada', image: './images/flag/Canada.webp' },
        { name: 'Chile', image: './images/flag/Chili.webp' },
        { name: 'Croatia', image: './images/flag/Croatia.webp' },
        { name: 'Finland', image: './images/flag/Finland.webp' },
        { name: 'Hungary', image: './images/flag/Hungary.webp' },
        { name: 'Latvia', image: './images/flag/Latvia.webp' },
        { name: 'Lithuania', image: './images/flag/Lithuania.webp' },
        { name: 'Luxembourg', image: './images/flag/Luxembourg.webp' },
        { name: 'Norway', image: './images/flag/Norway.webp' },
        { name: 'Romania', image: './images/flag/Romania.webp' },
        { name: 'Serbia', image: './images/flag/Serbia.webp' },
        { name: 'Ukraine', image: './images/flag/Ukraine.webp' },
        { name: 'United Kingdom', image: './images/flag/United-Kingdom.webp' }
    ];

    const handleClick = () => {
        Navigate('/contact-us');
    };

    return (
        <>
            <SEO title="Work Permit Information" />
            <Layout>

                {/* Banner/Section Title */}
                <div className="container mt-5">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img src="./images/job-offer.jpeg" alt="Work Permit" className="img-fluid rounded shadow" />
                        </div>
                        <div className="col-lg-6">
                            <SectionTitle
                                classes="text-left"
                                slogan="Opportunities Abroad"
                                title="Why Apply for a Work Permit?"
                            />
                            <p className="mt-3">A work permit allows individuals to work legally in a foreign country, opening doors to new career opportunities. Many work permits come with benefits such as the possibility of permanent residency, higher salary potential, and global networking opportunities.</p>
                            <p>Each country has specific eligibility requirements, processing times, and work conditions. It is essential to research and choose the right work permit based on your career goals and destination preferences.</p>
                        </div>
                    </div>
                </div>

                {/* Country Flags Section */}
                <div className="container mt-5">
                    <SectionTitle 
                        classes="text-left"
                        slogan="Explore Countries"
                        title="Work Permit Countries"
                    />
                    <div className="row">
                        {countries.map((country, index) => (
                            <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
                                <div className="country-flag-box text-center p-3 border rounded shadow-sm">
                                    <img src={country.image} alt={country.name} className="img-fluid mb-3" style={{ maxHeight: '150px', width: 'auto' }} />
                                    <h5 className="country-name">{country.name}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Contact Button Section */}
                <div className="text-center mt-5">
                    <button
                        className="rn-btn edu-btn bg-primary text-white px-4 py-2 rounded"
                        type="button"
                        onClick={() => window.location.href = '/contact-us'}  
                    >
                        <span>Contact Us for More Details</span>
                    </button>
                </div>

            </Layout>
        </>
    );
};

export default WorkPermit;
