import React, { useState, useEffect } from 'react';

const NewsTicker = () => {
  const messages = [
    "TOEFL Exam Centre Launching Soon...Register Now !!!",
    "Summer and Fall intake open...Register Now !!!",
    "New IELTS/TOEFL/PTE batches from April...Register Now !!!"
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 20000); // Change message every 3 seconds

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [messages.length]);

  // Inline CSS styles
  const headerStyle = {
    backgroundColor: '#ffff',
    color: '0000',
    padding: '10px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    position: 'relative',
    width: '100%',
    overflow: 'hidden'
  };

  const scrollingMessageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '50px', // Adjust this height as needed
    position: 'absolute',
    top: 0
  };

  const messageStyle = {
    whiteSpace: 'nowrap',
    animation: 'scrollMessage 30s linear infinite',
    paddingLeft: '100%' // Start from the right
  };

  // CSS Keyframes for scrolling text
  const keyframesStyle = `
    @keyframes scrollMessage {
      0% {
        transform: translateX(100%); /* Start from right */
      }
      100% {
        transform: translateX(-100%); /* End at left */
      }
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style> {/* Inline keyframes */}
      <header style={headerStyle}>
        <div style={scrollingMessageStyle}>
          <p style={messageStyle}>{messages[currentMessageIndex]}</p>
        </div>
      </header>
    </>
  );
};

export default NewsTicker;
