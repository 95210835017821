import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import AboutUsThree from './pages/innerpages/AboutUsThree';

import ComingSoon from './pages/innerpages/ComingSoon';
import ContactMe from './pages/innerpages/ContactMe';
import CourseThree from './pages/course/CourseThree';
import CourseFive from './pages/course/CourseFive';
import CourseCarousel from './pages/course/CourseCarousel';
import Unit1A from './pages/detailspages/Unit1A';
import Unit1B from './pages/detailspages/Unit1B';
import Unit1C from './pages/detailspages/Unit1C';
import Faq from './pages/innerpages/Faq';
import GalleryMasonry from './pages/innerpages/GalleryMasonry';
import HomeThree from './pages/homepages/HomeThree';
import LoginRegister from './pages/innerpages/LoginRegister';
import Testimonial from './pages/innerpages/Testimonial';
import Error from './pages/innerpages/Error';
import CareerAssessment from './pages/innerpages/CareerAssessment';
import ExclusiveUniversity from './pages/innerpages/ExclusiveUniversity';
import ElementaryCourses from './pages/course/ElementaryCourses';
import Elementary from './pages/course/Elementary';
import CheckoutPage from './pages/checkoutpage.js';
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";

import './assets/scss/style.scss';
import Unit1 from './pages/detailspages/Unit1';
import Unit1ACourse from './pages/detailspages/Unit1ACourse';
import Unit1BCourse from './pages/detailspages/Unit1BCourse';
import Unit1CCourse from './pages/detailspages/Unit1CCourse';
import PrForm from './pages/innerpages/PrForm';
import PteGeneral from './pages/innerpages/PteGeneral';
import StudentVisa from './pages/innerpages/StudentVisa';
import VisitorVisa from './pages/innerpages/VisitorVisa';
import Selt from './pages/innerpages/Selt';
import PTEVersant from './pages/innerpages/PTEVersant';
import IELTS from './pages/innerpages/IELTS';
import TOEFL from './pages/innerpages/TOEFL';
import SAT from './pages/innerpages/SAT';
import GreGmat from './pages/innerpages/GreGmat';
import AirTicket from './pages/innerpages/AIrTicket';
import StudyAbroad from './pages/innerpages/StudyAbroad';
import {AboutCountryUsa} from './pages/innerpages/AboutCountryUsa';
import { AboutCountryIreland } from './pages/innerpages/AboutCountryIreland';
import { AboutCountryUk } from './pages/innerpages/AboutCountryUk';
import { AboutCountryEurope } from './pages/innerpages/AboutCountryEurope';
import { AboutCountryAustralia } from './pages/innerpages/AboutCountryAustralia';
import { AboutCountryNewZealand } from './pages/innerpages/AboutCountryNewZealand';
import { AboutCountryCanada } from './pages/innerpages/AboutCountryCanada';
import { AboutCountryDubai } from './pages/innerpages/AboutCountryDubai';
import { WorkPermit } from './pages/innerpages/WorkPermit';


import PTE_A from './pages/innerpages/PTE_A';
import Gmat from './pages/innerpages/Gmat';
import SeltExam from './pages/innerpages/SeltExam';
import PTEVersantExam from './pages/innerpages/PTEVersantExam';
import CoursePage from './pages/course/CoursePage';
import Appointment from './pages/innerpages/Appoinment';
import Blog from './data/blog/blogpage';
import TransformingAmbitions from './data/blog/transforming-ambitions-global-achievements.js';
import EnglishLanguageTraining from './data/blog/english-language-training.js';
import BlendedHybridTrainingStructure from './data/blog/blended-hybrid-training-structure.js';
import WorkStudySettleAbroad from './data/blog/work-study-settle-abroad.js';
import Terms from './pages/Terms.js';
import Refund from './pages/Refund.js';
import Privacy from './pages/Privacy.js';


function App() {
    return (
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route exact path='/' element={<HomeThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-us'}`} element={<AboutUsThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-usa'}`} element={<AboutCountryUsa/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-ireland'}`} element={<AboutCountryIreland/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-uk'}`} element={<AboutCountryUk/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-europe'}`} element={<AboutCountryEurope/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-australia'}`} element={<AboutCountryAustralia/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-new-zealand'}`} element={<AboutCountryNewZealand/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-canada'}`} element={<AboutCountryCanada/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-dubai'}`} element={<AboutCountryDubai/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/work-permit'}`} element={<WorkPermit/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/coming-soon'}`} element={<ComingSoon/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<ContactMe/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/book-an-appointment'}`} element={<Appointment/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses'}`} element={<CoursePage/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-3'}`} element={<CourseThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-5'}`} element={<CourseFive/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-carousel'}`} element={<CourseCarousel/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses/elementary/unit1/'}`} element={<Unit1/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses/elementary/unit1/unit1a/'}`} element={<Unit1A/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses/elementary/unit1/unit1b/'}`} element={<Unit1B/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses/elementary/unit1/unit1c/:id'}`} element={<Unit1C/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/unit1a-course/'}`} element={<Unit1ACourse/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/unit1b-course/'}`} element={<Unit1BCourse/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/unit1c-course/'}`} element={<Unit1CCourse/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/career-assessment'}`} element={<CareerAssessment/>}/> 
                        <Route exact path={`${process.env.PUBLIC_URL + '/exclusive-university'}`} element={<ExclusiveUniversity/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses/elementarycourses'}`} element={<ElementaryCourses/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses/elementary'}`} element={<Elementary/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/checkout'}`} element={<CheckoutPage/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/payment-success'}`} element={<PaymentSuccess />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/payment-failure'}`} element={<PaymentFailure />} />
                        <Route exact path={`${process.env.PUBLIC_URL + '/terms'}`} element={<Terms/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/privacy'}`} element={<Privacy/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/refund'}`} element={<Refund/>}/>

                        <Route exact path={`${process.env.PUBLIC_URL + '/blog'}`} element={<Blog/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog/transforming-ambitions-global-achievements'}`} element={<TransformingAmbitions/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog/work-study-settle-abroad'}`} element={<WorkStudySettleAbroad/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog/english-language-training'}`} element={<EnglishLanguageTraining/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog/blended-hybrid-training-structure'}`} element={<BlendedHybridTrainingStructure/>}/>


                        <Route exact path={`${process.env.PUBLIC_URL + '/faq'}`} element={<Faq/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/air-tickets'}`} element={<AirTicket/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gallery-masonry'}`} element={<GalleryMasonry/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/student-visa'}`} element={<StudentVisa/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/study-abroad'}`} element={<StudyAbroad/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/selt'}`} element={<Selt/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/selt-exam'}`} element={<SeltExam/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/visitor-visa'}`} element={<VisitorVisa/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pr_Form'}`} element={<PrForm/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-versant'}`} element={<PTEVersant/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-versant-exam'}`} element={<PTEVersantExam/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-a'}`} element={<PTE_A/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-general'}`} element={<PteGeneral/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/ielts'}`} element={<IELTS/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/toefl'}`} element={<TOEFL/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/sat'}`} element={<SAT/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gre'}`} element={<GreGmat/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gmat'}`} element={<Gmat/>}/> 
                        <Route exact path={`${process.env.PUBLIC_URL + '/testimonial'}`} element={<Testimonial/>}/>
                        
                        <Route exact path={`${process.env.PUBLIC_URL + '/login'}`} element={<LoginRegister/>}/>
                        <Route path='*' element={<Error/>}/>
                    </Routes>
                </ScrollToTop>
            </Router>
    );
}

export default App;
