import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import HeaderSticky from './HeaderSticky';
import ResponsiveMenu from './ResponsiveMenu';
import NewsTicker from './NewsTicker';

const HeaderOne = ({ styles, disableSticky }) => {
    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);
    const [isSticky, setIsSticky] = useState(false);  // Track sticky state

    // Handle the toggle of the offcanvas menu
    const onCanvasHandler = () => setOffcanvasShow(prev => !prev);

    // Handle the toggle of the search popup
    const onSearchHandler = () => setSearchPopup(prev => !prev);

    // Apply class to body when searchPopup is active
    useEffect(() => {
        if (searchPopup) {
            document.body.classList.add('search-popup-active');
        } else {
            document.body.classList.remove('search-popup-active');
        }
    }, [searchPopup]);

    // Detect when the user scrolls and add sticky class
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const stickyClass = isSticky ? 'header-sticky' : '';  // Dynamically add sticky class

    // Define common button styles for reusability
    const btnStyles = {
        common: {
            backgroundColor: 'var(--color-primary)',
            color: 'white',
            padding: '12px 24px',
            fontSize: '14px',
            fontWeight: 600,
            border: 'none',
            borderRadius: '30px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease',
            display: 'inline-flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            textDecoration: 'none',
            boxShadow: '0 4px 8px rgba(0, 123, 255, 0.2)',
        },
    };

    return (
        <>
            {/* News Ticker Section */}
            <div className="bg-red-600 text-white py-2 text-center text-lg font-semibold shadow-md">
                <NewsTicker />
            </div>
            
            {/* Top Header Section */}
            <header className={`edu-header disable-transparent ${stickyClass} ${styles || ''}`}>
                <div className="container-fluid">
                    <div className="row align-items-center py-2">
                        {/* Logo */}
                        <div className="col-lg-2 col-md-3 col-6">
                            <div className="logo">
                                <Link to="/">
                                    <img className="logo-light" src="/images/logo/FETC-logo.png" alt="Main Logo" />
                                </Link>
                            </div>
                        </div>

                        {/* Center: Search Bar */}
                        <div className="col-lg-7 col-md-6 d-flex justify-content-center">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    placeholder="Search for..."
                                    className="form-control"
                                    style={{
                                        width: '100%',
                                        maxWidth: '320px', // Set width of search bar to 4 inches (approx. 320px)
                                        borderRadius: '300px',
                                        padding: '10px 100px',
                                    }}
                                />
                            </div>
                        </div>

                        {/* Right Section: Book Appointment, Login & Become Partner Buttons */}
                        <div className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-end gap-3">
                           {/* Become Partner Button */}
                           <Link to="/partner-with-us">
                                <button
                                    className="btn btn-partner"
                                    style={btnStyles.common}
                                    onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
                                    onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                                >
                                    Become Partner
                                </button>
                            </Link>
                            
                             {/* Book Appointment Button */}
                            <Link to="/book-an-appointment">
                                <button
                                    className="btn btn-appointment"
                                    style={btnStyles.common}
                                    onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
                                    onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                                >
                                    Book an Appointment
                                </button>
                            </Link>

                            {/* Login Button */}
                            <Link to="/login">
                                <button
                                    className="btn btn-login"
                                    style={btnStyles.common}
                                    onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
                                    onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                                >
                                    LOGIN
                                </button>
                            </Link>

                            
                        </div>
                    </div>
                </div>
                
                {/* Bottom Navigation Section */}
                <div className="container-fluid" style={{ paddingBottom: '0px' }}>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-start">
                            <nav className="mainmenu-nav">
                                <Nav />
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            {/* Responsive Menu */}
            <ResponsiveMenu show={offcanvasShow} onClose={onCanvasHandler} showSearch={searchPopup} onSearch={onSearchHandler} />
        </>
    );
};

export default HeaderOne;
