import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
const items = [
    {
      title: 'Mock Tests',
      description: 'Prepare for exams with our comprehensive mock tests tailored for various fields.',
      icon: 'icon-Pencil', // Custom icon class
      link: '/mock-test',
    },
    {
      title: 'Career Counselling',
      description: 'Receive expert guidance to navigate your career path, job opportunities, and career planning.',
      icon: 'icon-student-read', // Custom icon class
      link: '/career-assessment',
    },
    {
      title: 'Study Abroad Options',
      description: 'Explore global educational opportunities with our support for studying abroad.',
      icon: 'icon-Schoolbag', // FontAwesome class
      link: '/study-abroad',
    },
    {
      title: 'E-Learning',
      description: 'Access high-quality online learning designed to enhance your skills and proficiency.',
      icon: 'icon-Class', // Custom icon class
      link: '#',
    },
    {
      title: 'SOP Assistance',
      description: 'Get personalized support to draft, refine, and align your Statement of Purpose for academic applications.',
      icon: 'icon-Mind', // Custom icon class
      link: '#',
    },
    {
      title: 'Visa Interview Prep',
      description: 'Get your visa interview with confidence through our comprehensive preparation services.',
      icon: 'icon-Setting', // Custom icon class
      link: '#',
    },
  ];

const CategoryThree = ( { wrapperClass, styleClass } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-4 col-md-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-5">
                        <div className="inner" style={{height:'182px'}}>
                            <div className="icon">
                                <i className={ data.icon }></i>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.description }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default CategoryThree;