import React from 'react';
import HeaderOne from './header/HeaderOne';
import FooterOne from './footer/FooterOne';

const Layout = ({ children }) => {
    return (
        <>
            <main className="main-wrapper">
                <HeaderOne styles="header-transparent header-style-3" />
                {/* Add a div with margin-top or padding-top to ensure space after the header */}
                <div style={{ marginTop: '200px' }}>
                    {children}
                </div>
                <FooterOne />
            </main>
        </>
    );
};

export default Layout;
