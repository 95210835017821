import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Invalid email.";
    if (!/^\d{10,15}$/.test(formData.phone)) newErrors.phone = "Invalid phone number.";
    if (!formData.course) newErrors.course = "Please select a course.";
    if (!formData.termsAccepted) newErrors.termsAccepted = "You must accept the terms.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePayment = async () => {
    if (!validateForm()) return;
    setLoading(true);

    try {
      const orderId = `ORDER_${Date.now()}`;
      const requestData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        amount: parseInt(formData.course) * 100,
        order_id: orderId,
      };

      const response = await fetch("https://login.fetc.in/api/method/your_app.api.create_phonepe_order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();

      if (data?.success && data?.payment_url) {
        window.location.href = data.payment_url;
      } else {
        alert(`❌ Payment failed: ${data?.message || "Something went wrong."}`);
        navigate("/payment-failure");
      }
    } catch (error) {
      console.error("🔴 Payment error:", error);
      alert("❌ Payment request failed. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <SEO title="Checkout" />
      <BreadcrumbOne title="Checkout" rootUrl="/" parentUrl="Home" currentUrl="Checkout" />
      <div className="checkout-container">
        <form onSubmit={(e) => e.preventDefault()} className="checkout-form">
          <div className="left-bar">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-input" />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-input" />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="form-input" />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
          </div>
          <div className="right-bar">
            <div className="form-group">
              <label htmlFor="course">Select Course</label>
              <select id="course" name="course" value={formData.course} onChange={handleChange} className="form-input">
                <option value="">--Select--</option>
                <option value="25000">Syzygy ₹25,000</option>
                <option value="12000">Online Mock ₹12,000</option>
                <option value="499">PEARSON VERSANT ₹499</option>
              </select>
              {errors.course && <p className="error-message">{errors.course}</p>}
            </div>
            <div className="checkbox-container">
              <input type="checkbox" id="termsAccepted" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} />
              <label htmlFor="termsAccepted">
                I agree to the <a href="/terms" target="_blank">Terms & Conditions</a>
              </label>
              {errors.termsAccepted && <p className="error-message">{errors.termsAccepted}</p>}
            </div>
            <div className="form-group">
              <button type="button" className="pay-now-btn" onClick={handlePayment} disabled={loading}>
                {loading ? "Processing..." : "Pay Now"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CheckoutPage;
