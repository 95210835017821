import React from "react";
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";

const PaymentFailure = () => {
  return (
    <Layout>
      <SEO title="Payment Failed" />
      <BreadcrumbOne title="Payment Failed" rootUrl="/" parentUrl="Home" currentUrl="Payment Failed" />

      <div className="payment-failure-container">
        <div className="failure-card">
          <h2>❌ Payment Failed</h2>
          <p>Sorry! Your transaction could not be completed. Please try again.</p>
          <Link to="/checkout" className="retry-btn">Try Again</Link>
        </div>
      </div>

      <style jsx>{`
        .payment-failure-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60vh;
          text-align: center;
        }
        .failure-card {
          background: #fff3f3;
          padding: 40px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border: 1px solid #f44336;
        }
        h2 {
          color: #d32f2f;
          font-size: 2rem;
        }
        p {
          font-size: 1.4rem;
          margin-top: 10px;
        }
        .retry-btn {
          display: inline-block;
          margin-top: 20px;
          padding: 10px 20px;
          background: #f44336;
          color: white;
          text-decoration: none;
          border-radius: 5px;
          transition: 0.3s;
        }
        .retry-btn:hover {
          background: #c62828;
        }
      `}</style>
    </Layout>
  );
};

export default PaymentFailure;
