import React from "react";
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";

const PaymentSuccess = () => {
  return (
    <Layout>
      <SEO title="Payment Success" />
      <BreadcrumbOne title="Payment Success" rootUrl="/" parentUrl="Home" currentUrl="Payment Success" />

      <div className="payment-success-container">
        <div className="success-card">
          <h2>🎉 Payment Successful!</h2>
          <p>Thank you for your payment. Your transaction was completed successfully.</p>
          <Link to="/" className="home-btn">Go to Home</Link>
        </div>
      </div>

      <style jsx>{`
        .payment-success-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60vh;
          text-align: center;
        }
        .success-card {
          background: #f9f9f9;
          padding: 40px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        h2 {
          color: #2e7d32;
          font-size: 2rem;
        }
        p {
          font-size: 1.4rem;
          margin-top: 10px;
        }
        .home-btn {
          display: inline-block;
          margin-top: 20px;
          padding: 10px 20px;
          background: #4caf50;
          color: white;
          text-decoration: none;
          border-radius: 5px;
          transition: 0.3s;
        }
        .home-btn:hover {
          background: #388e3c;
        }
      `}</style>
    </Layout>
  );
};

export default PaymentSuccess;
